<template>
  <div style="width: 100%;
               margin-top: 100px;
               display:flex;
               justify-content: center">
    <div class="license_container">
      <div class="license_wrapper"
           v-for="item in licenses" :key="item">
        <div class="licence_title"
        >
          {{ item.l_title }}
        </div>
        <div class="licence_text"
             data-aos="fade-up"
             v-html="item.l_text"
        >

        </div>

      </div>

    </div>
  </div>

  <div style="width: 100%;
    display:flex;
    justify-content: center;
               margin-top: 100px;
               background-color: #ebebeb;
               padding-bottom: 20px;
                                   ">
    <div class="footer">
      <div class="footer_links">
        <li class="f_link">
          <router-link class="redirect_link" to="/about">
            Про нас
          </router-link>
        </li>
        <li class="f_link">
          <router-link to="/law" class="redirect_link">Правова інформація</router-link>
        </li>
        <li class="f_link">
          <router-link to="/cash" class="redirect_link">Кошти в кредит</router-link>
        </li>
        <li class="f_link">
          <router-link to="/good" class="redirect_link">Кредит на товар</router-link>
        </li>
        <li class="f_link">
          <router-link to="/news" class="redirect_link">Новини</router-link>
        </li>
        <li class="f_link">
          <router-link to="/contacts" class="redirect_link">Контакти</router-link>
        </li>
        <li class="f_link" @click="redirectHandler">
          <router-link to="" class="redirect_link">Особистий кабінет</router-link>
        </li>
      </div>
      <div class="footer_logo">
        <img src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/LOGO_HEADER-01.svg"
             class="t457__logo t457__img t-img" imgfield="img" alt="">
      </div>
      <div class="footer_banks-logos">
        <div class="bank_logo">
          <img class="serviceBankLogo"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/Visa_Inc_logosvg.webp"
               alt="visaLogo"
          />
        </div>
        <div class="bank_logo">
          <img class="serviceBankLogo"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1280px-Mastercard_20.webp"
               alt="mastercardLogo"
          />
        </div>
        <div class="bank_logo">
          <img class="serviceBankLogo"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/logo_easypay--dark.webp"
               alt="easyPayLogo"
          />
        </div>
        <div class="bank_logo">
          <img class="serviceBankLogo"
               src="../../../assets/logo3.png"
               alt="iPayLogo"
          />
        </div>
        <div class="bank_logo">
          <img class="serviceBankLogo"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/platon-logo.webp"
               alt="platonLogo"
          />
        </div>
<!--        <div class="bank_logo">-->
<!--          <img class="serviceBankLogo"-->
<!--               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/city24_logo.webp"-->
<!--               alt="city24Logo"-->
<!--          />-->
<!--        </div>-->
        <div class="bank_logo">
          <img class="serviceBankLogo"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1024px-Google_Pay_GP.webp"
               alt="googlePayLogo"
          />
        </div>
        <div class="bank_logo">
          <img class="serviceBankLogo"
               src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/1024px-Apple_Pay_log.webp"
               alt="applePayLogo"
          />
        </div>
        <div class="bank_logo">
          <img class="serviceBankLogoWide"
               src="../../../assets/BankID-logo.png"
               alt="bankIdLogo"
          />
        </div>
      </div>
      <div class="logos_bottom-text">
        Можливість сплачувати он-лайн, в терміналах самообслуговування або в відділенні банку
      </div>

    </div>
  </div>
  <div style="width: 100%;
               margin-top: 30px;
               margin-bottom: 30px;
               display:flex;
               justify-content: center;
               align-items: center;
               flex-direction: column;
               ">
    <span class="bottom_end">© 2022. Всі права захищені</span>
    <span class="bottom_end">ТОВ «Сучасний факторинг»- Свідоцтво №13102578 ФК №270
          про реєстрацію фінансової установи, видане Нацкомфінпослуг
          20.01.2011 р.
        </span>
  </div>
</template>

<script>
import AOS from "aos";

AOS.init();
export default {
  name: 'FooterApp',
  methods: {
    redirectHandler() {
      window.location.href = "https://my.sfactor.com.ua/";
    },
  },
  data() {
    return {
      licenses: [{
        l_title: 'Банківські реквізити', l_text: 'ТОВ «СУЧАСНИЙ ФАКТОРИНГ»<br/>' +
            'Код ЄДРПОУ: 35310044<br/>' +
            'Місцезнаходження: Україна, 01024, м. Київ, вул. Велика Васильківська, буд. 39А<br/>' +
            'IBAN: UA463808380000026509799999969<br/>' +
            'Банк отримувача: АТ «ПРАВЕКС-БАНК»<br/>' +
            'МФО 380838<br/>' +
            'IBAN: UA893348510000000000265011544<br/>' +
            'Банк отримувача: АТ «ПУМБ»<br/>' +
            'МФО 334851'
      },
        {
          l_title: 'Cвідоцтво', l_text: 'ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ «СУЧАСНИЙ ФАКТОРИНГ»\n' +
              'Офіційний email - info@sfactor.com.ua\n' +
              'Код території за КОАТУУ 8038200000\n' +
              'Код ЄДРПОУ: 35310044\n' +
              'Місцезнаходження: Україна, 01024, м. Київ, Печерський район, вул. Велика Васильківська, буд. 39-А.\n' +
              'Свідоцтво про реєстрацію фінансової установи ФК № В0000245 від 20.05.2021 року, видане ' +
              'НБУ'
        },
        {
          l_title: 'Державне врегулювання', l_text: '<a style="href="https://bank.gov.ua/" target="_blank">Національний банк України</a>:\n' +
              'Адреса: 01601, м. Київ, вул. Інститутська, 9, <br/>тел. 0 800 505 240\n' +
              '<br/><a href="https://dpss.gov.ua/" target="_blank">Державна служба України з питань безпечності харчових продуктів та захисту споживачів:</a>' +
              'Адреса: 01001, м. Київ, вул. Бориса Грінченка, 1, <br/>тел. +38 (044) 279 12 70'
        },
      ],

    }
  }
}
</script>

<style lang="scss" scoped>
.bold_title {
  color: #000;
  font-size: 45px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 700;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
  margin-bottom: 80px;
}

.site_ordinary_text {
  color: #000000;
  font-size: 20px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 300;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
}
.footer {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  width: 95%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;


  .footer_links {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 20px;
    flex-wrap: wrap;


    .f_link {
      color: #3b3b3b;
      font-size: 14px;
      font-weight: 400;
      font-family: 'Montserrat' ;
      text-decoration: none;
      list-style: none;
      cursor: pointer;
      margin: 5px;
      .redirect_link {
        color: #3b3b3b;
      }

      .redirect_link:hover {
        color: #12b423;
      }
    }
  }

  .footer_logo {
    max-width: 13%;
    min-width: 100px;
    width: 100%;
    align-self: center;
    margin-top: 20px;
  }

  .footer_banks-logos {
    display: flex;
    justify-content: space-between;
    align-self: center;
    max-width: 60%;
    width: 100%;
    margin-top: 30px;
    flex-wrap: wrap;


    .bank_logo {
      width: 70px;
      height: 30px;
      border-radius: 5px;
      background-color: #d4d4d4;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;

      .serviceBankLogo {
        width: 60%;
      }
      .serviceBankLogoWide {
        width: 100%;
      }
    }
  }

  .logos_bottom-text {
    color: #3b3b3b;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Montserrat';
    opacity: 0.5;
    margin-top: 10px;
    text-align: center;
  }
}

.bottom_end {
  font-size: 11px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.55;
  font-weight: 400;
  color: rgb(59, 59, 59);
}

.license_container {
  display: flex;
  max-width: 1400px;
  width: 95%;
  background-color: #1f1f1f;
  box-shadow: 2px 2px 7px 0px rgb(0 0 0 / 20%);
  border-radius: 10px;
  min-height: 350px;
  position: relative;
  flex-wrap: wrap;
  justify-content: space-around;


  .license_wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 10px 20px 10px;
    max-width: 300px;
    width: 100%;


    .licence_title {
      color: #ffffff;
      font-size: 22px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      font-weight: 700;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      margin-bottom: 30px;
    }

    .licence_text {
      color: #bfbfbf;
      font-size: 14px;
      font-family: 'Montserrat', Arial, sans-serif;
      line-height: 1.55;
      font-weight: 400;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
    }
  }
}

.license_container:after {
  content: '';
  position: absolute;
  background-color: white;
  width: 1px;
  height: 60%;
  top: 20%;
  right: 35%;
}

.license_container:before {
  content: '';
  position: absolute;
  background-color: white;
  width: 1px;
  height: 60%;
  top: 20%;
  left: 34%;
}

.site_ordinary_bold_text {
  color: #000000;
  font-size: 18px;
  font-family: 'Montserrat', Arial, sans-serif;
  line-height: 1.5;
  font-weight: 600;
  background-position: center center;
  border-color: transparent;
  border-style: solid;
}
.link_bank{
 font-size: xxx-large;
}
@media (max-width: 1200px) {
  .license_container {
    .license_wrapper {
      max-width: 370px;
      text-align: center;
    }

  }
  .license_container:after {
    display: none;
  }
  .license_container:before {
    display: none;
  }
}

@media (max-width: 1100px) {
  .feedback_form {
    .form_container {
      a {
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: 940px) {
  .feedback_form {
    .form_container {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media (max-width: 620px) {
  .feedback_form {
    .form_agreements {
      font-size: 10px;
      text-align: center;
    }
  }
  .contacts_wrapper {
    .blocks_wrapper {
      .contacts_block {
        max-width: 90%;
      }
    }
  }
}

@media (max-width: 500px) {
  .license_wrapper {
    max-width: 300px;
  }
}
</style>

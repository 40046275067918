<template>
  <div class="Services_wrapper">
    <span class="simple_title">
     Послуги
    </span>
    <div class="simple_items_container">

      <div class="simple_element" style="background-color: #fef0f4">

        <div class="simple_innerBlock">
          <span
              class="innerBlock_title"
              data-aos="fade-up"
              data-aos-duration="1000"
          >
            Позики
          </span>

          <img class="innerBlock_img"
               data-aos="zoom-in"
               data-original="https://static.tildacdn.com/tild3962-3839-4362-b861-323534376163/1-12.svg"
               alt="picture"
               src="https://static.tildacdn.com/tild3962-3839-4362-b861-323534376163/1-12.svg">

        </div>


        <span
            class="simple_InnerText"
            data-aos="fade-up"
            data-aos-duration="1000"
        >
          Фінансова підтримка unbankable-українців. Надання позик на будь-які цілі онлайн
        </span>
      </div>
      <div class="simple_element" style="background-color: #fff0e3">

        <div class="simple_innerBlock">
          <span
              class="innerBlock_title"
              data-aos="fade-up"
              data-aos-duration="1000"
          >
            Кредити
          </span>

          <img class="innerBlock_img"
               data-aos="zoom-in"
               data-original="https://static.tildacdn.com/tild3436-3066-4230-b864-363736623065/-06.svg"
               alt="picture"
               src="https://static.tildacdn.com/tild3436-3066-4230-b864-363736623065/-06.svg">

        </div>


        <span
            class="simple_InnerText"
            data-aos="fade-up"
            data-aos-duration="1000"
        >
          Надання споживчих кредитів на придбання товарів та послуг в роздрібних мережах України
        </span>
      </div>
<!--      <div class="simple_element" style="background-color: #f1ecfe">-->

<!--        <div class="simple_innerBlock">-->
<!--          <span-->
<!--              class="innerBlock_title"-->
<!--              data-aos="fade-up"-->
<!--              data-aos-duration="1000"-->
<!--          >-->
<!--              Лізинг-->
<!--          </span>-->

<!--          <img class="innerBlock_img"-->
<!--               data-aos="zoom-in"-->
<!--               data-original="https://static.tildacdn.com/tild3736-3962-4064-b164-353063383262/-07.svg"-->
<!--               alt="picture"-->
<!--               src="https://static.tildacdn.com/tild3736-3962-4064-b164-353063383262/-07.svg">-->

<!--        </div>-->


<!--        <span-->
<!--            class="simple_InnerText"-->
<!--            data-aos="fade-up"-->
<!--            data-aos-duration="1000"-->
<!--        >-->
<!--         Придбання майна для фізичних і юридичних осіб на умовах фінансового лізингу-->
<!--        </span>-->
<!--      </div>-->
      <div class="simple_element" style="background-color: #e9f5ff">

        <div class="simple_innerBlock">
          <span
              class="innerBlock_title"
              data-aos="fade-up"
              data-aos-duration="1000"
          >
            Факторинг
          </span>

          <img class="innerBlock_img"
               data-aos="zoom-in"
               data-original="https://static.tildacdn.com/tild3630-6436-4033-a439-313663333337/-08.svg"
               alt="picture"
               src="https://static.tildacdn.com/tild3630-6436-4033-a439-313663333337/-08.svg">

        </div>


        <span
            class="simple_InnerText"
            data-aos="fade-up"
            data-aos-duration="1000"
        >
          Комплекс фінансових послуг, що надаються клієнту
          в обмін на відступлення дебіторської заборгованості.
          Покупка рахунків-фактур клієнта на певних умовах
        </span>
      </div>



    </div>

  </div>
</template>


<style lang="scss" scoped>
.Services_wrapper {
  max-width: 1400px;
  width:95%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 60px;
  margin-bottom: 60px;

  .simple_title {
    color: #12b423;
    font-size: 45px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.1;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-bottom: 60px;
    margin-left: 30px;
  }
  .simple_items_container {
    display: flex;
    justify-content: space-around;
    width: 98%;
    flex-wrap: wrap;

    .simple_element {
      border-radius: 15px;
      background-color: #ffffff;
      background-position: center center;
      border-color: transparent;
      border-style: solid;
      max-width: 40%;
      padding: 30px;
      min-width: 500px;
      margin-bottom: 40px;
      height: 230px;
      transition: all 0.5s;

      .simple_innerBlock {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 40px;

        .innerBlock_title {
          max-width: 50%;
          color: #12b422;
          font-size: 30px;
          font-family: 'Montserrat',Arial,sans-serif;
          line-height: 1.55;
          font-weight: 700;
          background-position: center center;
          border-color: transparent;
          border-style: solid;
        }

        .innerBlock_img {
          max-width: 120px;
        }
      }

      .simple_InnerText {
        color: #1f1f1f;
        font-size: 16px;
        font-family: 'Montserrat', Arial, sans-serif;
        line-height: 1.55;
        font-weight: 300;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
      }
    }
    .simple_element:hover {
      transform: scale(1.05);
    }
  }
}
@media (max-width: 720px) {
  .Services_wrapper{
    .simple_title{
      font-size: 40px;
      margin-bottom: 20px;
    }
    .simple_under_title{
      max-width: 70%;
    }
  }
}
@media (max-width: 650px) {
  .Services_wrapper {
    .simple_items_container {
      .simple_element {
        border-radius: 15px;
        background-color: #ffffff;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 40%;
        padding: 15px;
        min-width: 400px;
        margin-bottom: 40px;
        height: 200px;
        .simple_innerBlock{
          margin-bottom: 20px;
          .innerBlock_title{
            font-size: 20px;
          }
          .innerBlock_img{
            max-width: 80px;
          }
        }
        .simple_InnerText{
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 490px) {
  .Services_wrapper {
    margin-bottom: 20px;
    .simple_title{
      font-size: 35px;
    }
    .simple_items_container {
      .simple_element {
        border-radius: 15px;
        background-color: #ffffff;
        background-position: center center;
        border-color: transparent;
        border-style: solid;
        max-width: 40%;
        padding: 15px;
        min-width: 90%;
        margin-bottom: 20px;
        height: 250px;
        .simple_innerBlock{
          margin-bottom: 10px;
          flex-direction: column-reverse;
          align-items: center;
          .innerBlock_title{
            font-size: 30px;
          }
          .innerBlock_img{
            max-width: 100px;
          }
        }
        .simple_InnerText{
          font-size: 14px;
        }
      }
    }
  }
}
</style>

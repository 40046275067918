<template>
  <div class="LawInfo_wrapper">
    <span class="simple_title">
      Правова інформація
    </span>
    <span class="simple_under_title">
      Ми цінуємо довіру клієнтів
    </span>
    <div class="info_block">
      <span class="lawInfoTitle">Юридичні документи компанії</span>
      <div
          class="links_container"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
      >
        <a
            class="info_link"
            style="background-color: #fef0f4"
            @click=linkRedirector(link.href)
            v-for="(link, index) in LegalDocuments"
            :key=index>
          {{ link.title }}
        </a>
      </div>
      <div class="divider"></div>
    </div>
    <div class="info_block">
      <span class="lawInfoTitle">Публічна інформація</span>
      <div
          class="links_container"
          data-aos="fade-up"
      >
        <a
            class="info_link"
            style="background-color: #f1ecfe"
            @click=linkRedirector(link.href)
            v-for="(link, index) in PublicInformation"
            :key=index>
          {{ link.title }}
        </a>
      </div>
      <div class="divider"></div>
    </div>
    <div class="info_block">
      <span class="lawInfoTitle">Послуги</span>
      <div
          class="links_container"
          data-aos="fade-up"
      >
        <a
            class="info_link"
            style="background-color: #e9f5ff"
            @click=linkRedirector(link.href)
            v-for="(link, index) in CreditProducts"
            :key=index>
          {{ link.title }}
        </a>
      </div>
      <div class="divider"></div>
    </div>
    <div class="info_block">
      <span class="lawInfoTitle">Архівна інформація</span>
      <div
          class="links_container"
          data-aos="fade-up"
      >
        <a
            class="info_link"
            style="background-color: #fff0e3"
            @click=linkRedirector(link.href)
            v-for="(link, index) in ArchivalInformation"
            :key=index>
          {{ link.title }}
        </a>
      </div>
      <div class="divider"></div>
    </div>
    <div class="info_block">
      <span class="lawInfoTitle">Звітність</span>
      <div
          class="links_container"
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
      >
        <a
            class="info_link"
            style="background-color: #fef0f4"
            @click=linkRedirector(link.href)
            v-for="(link, index) in Reporting"
            :key=index>
          {{ link.title }}
        </a>
      </div>
      <div class="divider"></div>
    </div>

  </div>
</template>
<script>

import router from "@/router";

export default {

  name: 'law_info',
  components: {},
  methods: {
    linkRedirector(link){
      if(link === "1") {
        router.push('/creditCalculator');
      }
      if(link !== "1") {
        window.open(link, '_blank');
      }
    },
  },
  data() {
    return {
      LegalDocuments: [
        {title:"Свідоцтво про реєстрацію фінансової установи від 2021 року", href:"https://slvr.one/!/1Xl0i"},
        {title:"Рішення про ємісію облігацій 2020 року", href:"https://slvr.one/!/RScRN"},
        {title:"Торговельна марка Сучасний Факторинг", href:"https://slvr.one/!/fYr1R"},
        {title:"Торговельна марка SFactor", href:"https://slvr.one/!/szkPy"},
        {title:"Календарний план на 2022 рік, розміщений 31.01.2022 р.", href:"https://slvr.one/!/7dOf2"},
        {title:"Календарний план на 2022 рік, розміщений 31.01.2022 р. (з електронним підписом)", href:"https://slvr.one/!/JtVp4"},
        {title:"Календарний план на 2023 рік, розміщений 30.01.2023 р.", href:"https://slvr.one/!/pRTMm"},
        {title:"Річна інформація емітента цінних паперів за 2019 рік розміщена 30.04.2021", href:"https://slvr.one/!/xUsYn"},
        {title:"Річна інформація емітента цінних паперів за 2020 рік розміщена 30.04.2021", href:"https://slvr.one/!/TkJ9v"},
        {title:"Витяг із Державного реєстру фінансових установ від 05.11.2024", href:"https://slvr.one/!/YTqPr "}
      ],
      PublicInformation: [
        {title:"Загальна інформація про фінансову установу", href:"https://slvr.one/!/JMxfM"},
        {title:"Інформація для ознайомлення перед укладанням договору", href:"https://slvr.one/!/NgDpz"},
        {title:"Інформація про захист прав споживачів", href:"https://slvr.one/!/X9hZC"},
        {title:"Перелік кредитних посередників", href:"https://slvr.one/!/G3AfN"},
        {title:"Умови користування сайтом", href:"https://slvr.one/!/KwW2a"},
        {title:"Політика конфіденційності та захисту персональних даних", href:"https://slvr.one/!/p6WgJ"},
        {title:"Політика cookie", href:"https://slvr.one/!/8YzB0"},
        {title:"Положення про порядок розгляду звернень споживачів фінансових послуг", href:"https://slvr.one/!/AR4I6"},
        {title:"Розкриття відомостей про структуру власності", href:"https://slvr.one/!/EdOvp"},
        {title:"Порядок взаємодії зі споживачами фінансових послуг", href:"https://slvr.one/!/T4Rg9"},
        {title:"повідомлення Товариства про уповноваженого представника", href:"https://slvr.one/!/HHodT"},
        {title:"Інформація для клієнта з питань врегулювання простроченої заборгованості", href:"https://slvr.one/!/sxeya"},
        // {title:'Інформація ТОВ "КОЛЕКТОРСЬКЕ АГЕНСТВО ФАКТОР"', href:"https://slvr.one/!/AErbu"},
        {title:'Інформація ТОВ "КОЛЕКТОРСЬКА КОМПАНІЯ ФАКТОР""', href:"https://slvr.one/!/K9NDc"},
        {title:'Інформація ТОВ "ПРІМОКОЛЕКТ ЦЕНТР ГРОШОВИХ ВИМОГ"', href:"https://slvr.one/!/kuw3j"},
        {title:'Інформація ТОВ КОЛЕКТОРСЬКА КОМПАНІЯ\n' +
              '"КАРМА"', href:"https://slvr.one/!/PSXji"},
        {title:'Інформація ТОВ «КОЛЕКТОРСЬКА КОМПАНІЯ УА»', href:"https://slvr.one/!/bayny"},
        {title:'Інформація ТОВ "ЄАПБ"', href:"https://slvr.one/!/xsVYp"},
        {title:'Інформація ТОВ "УКРБОРГ"', href:"https://slvr.one/!/dNYaf"},
        {title:'Інформація ТОВ "ПЕРША ПРОФЕСІЙНА ПРИВАТНА ВИКОНАВЧА СЛУЖБА"', href:"https://slvr.one/!/A9cFi"},
        {title:'Інформація ТОВ «ФІНКОЛЛЕКТ УКРАЇНА»', href:"https://slvr.one/!/e2HlF"},
        {title:'Інформація ТОВ «ВЕРДИКТ КОНСАЛТИНГ »', href:"https://slvr.one/!/SExj5"},
        {title:'Згода на обробку персональних даних', href:"https://slvr.one/!/72QWj"},
        {title:'Інформація ТОВ "КОМПАНІЯ АКСІЛІУМ"', href:"https://slvr.one/!/bWJWQ"},
        {title:'Інформація ТОВ "КРЕДИТЕКСПРЕС ЮКРЕЙН ЕЛ.ЕЛ.СІ."', href:"https://slvr.one/!/Boceb"},
        {title:'Взаємодія з захищеними особами', href:"https://slvr.one/!/LVqvh"},
        {title:'Положення про використання електронного підпису та електронної печатки', href:"https://slvr.one/!/l5hqX"},
        {title:'Повідомлення щодо передачі інформації до кредитного реєстру НБУ', href:"https://slvr.one/!/j3U2V"},

      ],
      CreditProducts: [
        {title:"Кредитні продукти", href:"1"},
        {title:"Інформація про вартість, ціну/тарифи, розмір плати (проценти)", href:"https://slvr.one/!/10aR6"},
        {title:"Інформація про вартість фінансових послуг та факторингу", href:"https://slvr.one/!/yjV3S"},
        {title:"Інформація про перелік послуг, що надаються", href:"https://slvr.one/!/ZwLY3"},
        // {title:"Публічна пропозиція укладення Кредитного договору по продукту \"Кошти в кредит\"", href:"https://slvr.one/!/niU5Y"},
        {title:'Публічна пропозиція на укладення Кредитного договору "Кредит на товар"', href:"https://slvr.one/!/06zXN"},
        {title:"Примірний кредитний договір \"Кредит на товар\"", href:"https://slvr.one/!/SFYLx"},
        {title:"Примірний кредитний договір \"Кошти в кредит\"", href:"https://slvr.one/!/0gNBP"},
        {title:"Правила надання коштів та банківських металів у кредит", href:"https://slvr.one/!/KwndQ"},
        {title:"Правила надання послуг з факторингу", href:"https://slvr.one/!/51NQ4"},
        {title:"Примірний договір надання послуг з факторингу", href:"https://slvr.one/!/p1H93"},
        {title:"Істотні характеристики продукту «Кредит на товар (вище однієї мін. зароб. плати)", href:"https://slvr.one/!/IqnGp"},
        {title:"Істотні характеристики продукту «Кредит на товар» (менше однієї мін. зароб. плати)", href:"https://slvr.one/!/dEOhL"},
        {title:"Істотні характеристики продукту \"Кошти в кредит\"", href:"https://slvr.one/!/qTHAv"},
        {title:"Публічна пропозиція на укладення Договору «Кредит на товар»", href:"https://slvr.one/!/1JUoA"},
      ],
      ArchivalInformation: [
        {title:"Свідоцтво про реєстрацію фін установи від 2011 року", href:"https://slvr.one/!/xCV48"},
        {title:"Приклад типового Договору позики №1 від «05» липня 2017 р.", href:"https://slvr.one/!/ORtz5"},
        {title:"Приклад типового Договору позики №2 від «05» липня 2017 р.", href:"https://slvr.one/!/mDWAu"},
        {title:"Приклад типового Договору позики №3 від «05» липня 2017 р.", href:"https://slvr.one/!/8qDO5"},
        {title:"Приклад типового Договору позики №4 від «05» липня 2017 р.", href:"https://slvr.one/!/FVdIb"},
        {title:"Приклад типового Договору позики №5 від «05» липня 2017 р.", href:"https://slvr.one/!/86dN5"},
        {title:"Приклад типового Договору позики №6 від «05» липня 2017 р.", href:"https://slvr.one/!/EeZU0"},
        {title:"Правила кредитування/позик ТОВ «Сучасний факторинг» 2017 р.", href:"https://slvr.one/!/rZZsF"},
        {title:"Правила надання коштів у позику від 23.02.2021 року", href:"https://slvr.one/!/mcNvm"},
        {title:"Примірний договір Позики від 23.02.2021 року", href:"https://slvr.one/!/4miyW"},
        {title:"Примірний кредитний договір від 23.02.2021 року", href:"https://slvr.one/!/kVwga"},
        {title:"Публічна пропозиція Кредит на товар від 12.07.2021", href:"https://slvr.one/!/TGCKK"},
        {title:"Публічна пропозиція Кошти в кредит від 12.07.2021", href:"https://slvr.one/!/zeVax"},
        {title:"Публічна пропозиція укладення Кредитного договору по продукту \"Кошти в кредит\" від 14.12.2023", href:"https://slvr.one/!/rsT9w"},
        {title:"Примірний договір фінансового лізингу від 15.02.2021", href:"https://slvr.one/!/bZ1Wy"},
        {title:"Кредитний договір по продукту «Кредит на товар» від 02.08.2021 року", href:"https://slvr.one/!/3w4hS"},
        {title:"Кредитний договір по продукту «Кредит на товар» від 05.02.2022", href:"https://slvr.one/!/nXGRS "},
        {title:"Кредитний договір по продукту «Кошти в кредит» від 02.08.2021 року", href:"https://slvr.one/!/wh33U"},
        {title:"Кредитний договір по продукту «Кошти в кредит» від 19.10.2021", href:"https://slvr.one/!/8O96j"},
        {title:"Кредитний договір по продукту «Кредит на товар» від 19.10.2021", href:"https://slvr.one/!/xTmJT"},
        {title:"Публічна пропозиція «Кредит на товар» від 19.10.2021", href:"https://slvr.one/!/5PMPm"},
        {title:"Публічна пропозиція «Кошти в кредит» від 19.10.2021", href:"https://slvr.one/!/O4Ajk"},
        {title:"Публічна пропозиція Кредит на товар від 05.02.2022", href:"https://slvr.one/!/Rwy65"},
        {title:"Кредитний договір по продукту «Кошти в кредит» від 05.02.2022", href:"https://slvr.one/!/WNQoN"},
        {title:"Публічна пропозиція «Кошти в кредит» від 05.02.2022", href:"https://slvr.one/!/qXK1Y"},
        {title:"Публічна пропозиція Кредит на товар від 02.11.2022", href:"https://slvr.one/!/P03PF"},
        {title:"Кредитний договір по продукту «Кредит на товар» від 25.10.2022", href:"https://slvr.one/!/tyRzn"},
        {title:"Кредитний договір по продукту «Кошти в кредит» від 22.12.2022", href:"https://slvr.one/!/ukb2M"},
        {title:"Публічна пропозиція «Кошти в кредит» від 22.12.2022", href:"https://slvr.one/!/HwyTY"},
        {title:"Розкриття відомостей про структуру власності станом на 01.01.2023 р.", href:"https://slvr.one/!/gkOvd"},
        {title:"Право надання послуг з фінансового лізингу(розпорядження Нацкомфінпослуг) від 06.06.2019 р.", href:"https://slvr.one/!/7VR5M"},
        {title:"Право надання послуг факторингу, надання коштів у позику від 04.07.2017 р.", href:"https://slvr.one/!/4ANyT"},
        {title:"Публічна пропозиція Кредит на товар від 13.12.2023", href:"https://slvr.one/!/nrcfy"},
        {title:"Кредитний договір по продукту \"Кредит на товар\" від 13.12.2023", href:"https://slvr.one/!/J0WAy"},
        {title:"Кредитний договір по продукту \"Кошти в кредит\" від 13.12.2023", href:"https://slvr.one/!/FX1aM"},
        {title:"Правил надання коштів у позику на умовах фінансового кредиту від 04.01.2022 року", href:"https://slvr.one/!/Mzycg"},
        {title:"Правила надання послуг з фінансового лізингу від 15.02.2021 року", href:"https://slvr.one/!/aGeMV"},
        {title:"Правила надання послуг з факторингу від 15.02.2021 року", href:"https://slvr.one/!/POlfQ"},
        {title:"Примірний договір фінансового лізингу 15.02.2021 року", href:"https://slvr.one/!/K5MGx"},
        {title:"Примірний договір надання послуг з факторингу від 15.02.2021 року", href:"https://slvr.one/!/FuQU3"},
      ],
      Reporting: [
        {title:"Звіт незалежного аудитора за 2020 р.", href:"https://slvr.one/!/kfieN"},
        {title:"Звіт незалежного аудитора за 2019 р.", href:"https://slvr.one/!/vr2Ux"},
        {title:"Звіт незалежного аудитора за 2018 р.", href:"https://slvr.one/!/rAezQ"},
        {title:"Звіт незалежного аудитора за 2017 р.", href:"https://slvr.one/!/rKLql"},
        {title:"Звіт незалежного аудитора за 2016 р.", href:"https://slvr.one/!/gVeuS"},
        {title:"Звіт незалежного аудитора за 2015 р.", href:"https://slvr.one/!/6uITO"},
        {title:"Баланс за 2020 р.", href:"https://slvr.one/!/6fln5"},
        {title:"Баланс за 2019 р.", href:"https://slvr.one/!/cA5FV"},
        {title:"Баланс за 2018 р.", href:"https://slvr.one/!/vmh1F"},
        {title:"Баланс за 2017 р.", href:"https://slvr.one/!/ppBkb"},
        {title:"Баланс за 2016 р.", href:"https://slvr.one/!/2nlcR"},
        {title:"Баланс за 2015 р.", href:"https://slvr.one/!/GtAlf"},
        {title:"Примітки до річної фінансової звітності за 12 місяців 2020 року.", href:"https://slvr.one/!/yYPim"},
        {title:"Звіт незалежного аудитора з надання впевненності за 2020 р.", href:"https://slvr.one/!/fb38w"},
        {title:"Баланс за 2021 р.", href:"https://slvr.one/!/cghIB"},
        {title:"Баланс за 2022 р.", href:"https://slvr.one/!/e8FW3"},
        {title:"Звіт незалежного аудитора за 2023 р.", href:"https://slvr.one/!/Xr0wn"},
        {title:"Звіт незалежного аудитора з надання впевненності за 2023 р.", href:"https://slvr.one/!/XwzEH"},
      ],
    }
  }
}
</script>
<style lang="scss" scoped>
.LawInfo_wrapper {
  max-width: 1400px;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-bottom: 30px;
  .simple_title {
    color: #12b423;
    font-size: 45px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.1;
    font-weight: 700;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    margin-bottom: 30px;
  }

  .simple_under_title {
    color: #000000;
    font-size: 16px;
    font-family: 'Montserrat', Arial, sans-serif;
    line-height: 1.55;
    font-weight: 300;
    background-position: center center;
    border-color: transparent;
    border-style: solid;
    max-width: 40%;
    text-align: center;
    margin-bottom: 30px;

  }
  .info_block{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .lawInfoTitle{
      font-size: 18px;
      line-height: 1.55;
      font-family: 'Montserrat', Arial, sans-serif;
      font-weight: 500;
      color: #000;
      margin-bottom: 30px;

    }
  }

}
.info_link {
  height: 40px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 5px 10px 5px 0;
  color: #000;
  border-radius: 15px;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  font-family: Montserrat,sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.links_container{
  display: flex;
  flex-wrap: wrap;
  a{
    text-decoration: none;
  }
}
.divider{
  height: 1px;
  width: 60%;
  background-color: black;
  align-self: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
@media (max-width: 500px) {
  .LawInfo_wrapper {
    .simple_title {
      font-size: 40px;
      text-align: center;
    }
}
  .info_link{
    font-size: 12px;
    height: 50px;
  }
  .links_container{
    padding:5px 5px 20px 5px;
  }
}
</style>
